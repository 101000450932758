import { Search } from './Search'
import Add from './icons/Add'
import Coin from './icons/Coin'
import Diamond from './icons/Diamond'
import Logout from './icons/Logout'
import Privacy from './icons/Privacy'
import Support from './icons/Support'
import TermsConditions from './icons/Terms&Conditions'
import UserProfile from './icons/UserProfile'
import {
    Container,
    LoginButtonContainer,
    LoginButton,
    LoginButtonText,
    UserDetailContainer,
    BalanceInfoContainer,
    CoinContainer,
    DiamondBalanceContainer,
    CoinBalanceContainer,
    Balance,
    InfoWrapper,
} from './styles'
import { EventTracking } from '@/helpers'
import { compressedUserPhoto } from '@/helpers/compressedUserPhoto'
import convertToIndonesianCurrency from '@/helpers/convertToIndonesianCurrency'
import { setShowLoginPrompt } from '@/store/actions/player'
import { setUserWallet } from '@/store/actions/user'
import * as UserSelector from '@/store/selectors/user'
import { getUserWallet } from '@/utils/api-ssr'
import { logOut } from '@/utils/auth'
import Icon from '@ant-design/icons'
import { Dropdown, Space, Avatar, Tooltip } from 'antd'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

export const TopSearch = () => {
    const navElement = useRef(false)
    const [isOpen, setIsOpen] = useState(false)
    const userWallet = useSelector(UserSelector._getUserWallet)
    const dispatch = useDispatch()
    const { data: session, status } = useSession()
    const router = useRouter()

    useEffect(() => {
        const getUserWalletDetails = async () => {
            const userWallet = await getUserWallet(session?.token)
            dispatch(setUserWallet(userWallet?.data))
        }

        if (session?.userName) {
            getUserWalletDetails()
        }
    }, [dispatch, session?.token, session?.userName])

    const totalCoins =
        userWallet?.coinBalance + userWallet?.freeCoinBalance || 0
    const diamondBalance = userWallet?.diamondBalance || 0

    const items = [
        {
            key: '1',
            label: (
                <Link href={`/my-profile`} prefetch={false}>
                    <a>Profil</a>
                </Link>
            ),
            icon: <Icon component={UserProfile} />,
        },
        {
            key: '2',
            label: (
                <Link href={`/subscription`} prefetch={false}>
                    <a
                        onClick={(e) => {
                            e.preventDefault()

                            EventTracking(
                                'subscription page from web opened',
                                {
                                    userType: session?.isLoggedin
                                        ? 'registered'
                                        : 'guest',
                                    loginAuth: session?.type,
                                    userName: session?.userName,
                                    userId: session?.userInfo?.id,
                                    activeCoinBalance: totalCoins,
                                    source: 'langganan dropdown',
                                },
                                session
                            )

                            router.push('/subscription')
                        }}
                    >
                        Langganan
                    </a>
                </Link>
            ),
            icon: (
                <img
                    style={{ width: '16px', height: '16px' }}
                    alt=""
                    src="/img/subscribe.svg"
                />
            ),
        },
        {
            key: '3',
            label: (
                <Link href={`/voucher/redeem`} prefetch={false}>
                    <a
                        onClick={(e) => {
                            e.preventDefault()

                            // EventTracking(
                            //     'subscription page from web opened',
                            //     {
                            //         userType: session?.isLoggedin
                            //             ? 'registered'
                            //             : 'guest',
                            //         loginAuth: session?.type,
                            //         userName: session?.userName,
                            //         userId: session?.userInfo?.id,
                            //         activeCoinBalance: totalCoins,
                            //         source: 'langganan dropdown',
                            //     },
                            //     session
                            // )

                            router.push('/voucher/redeem')
                        }}
                    >
                        Tukar voucher
                    </a>
                </Link>
            ),
            icon: (
                <img
                    style={{ width: '16px', height: '16px' }}
                    alt="tukar voucher"
                    src="/img/tukar_voucher.svg"
                />
            ),
        },
        {
            key: '4',
            label: (
                <Link href="/tnc" prefetch={false}>
                    <a>Syarat & Ketentuan</a>
                </Link>
            ),
            icon: <Icon component={TermsConditions} />,
        },
        {
            key: '5',
            label: (
                <Link href="/policy" prefetch={false}>
                    <a>Kebijakan Privasi</a>
                </Link>
            ),
            icon: <Icon component={Privacy} />,
        },
        {
            key: '6',

            label: (
                <a
                    href="https://bit.ly/SupportNoice"
                >
                    Support
                </a>
            ),
            icon: <Icon component={Support} />,
        },
        {
            key: '7',
            label: 'Keluar',
            icon: <Icon component={Logout} />,
            onClick: () => {
                if (
                    router.pathname.includes('register') ||
                    router.pathname.includes('/my-profile')
                ) {
                    logOut({ callbackUrl: '/' })
                    return
                }

                logOut()
            },
        },
    ]

    return (
        <Container ref={navElement} showBottomBorder>
            <Search />

            <InfoWrapper>
                {!session?.isLoggedin && (
                    <LoginButtonContainer>
                        <LoginButton
                            onClick={() => {
                                dispatch(setShowLoginPrompt(true))
                            }}
                        >
                            <LoginButtonText>Masuk</LoginButtonText>
                        </LoginButton>
                    </LoginButtonContainer>
                )}

                {status === 'authenticated' && session?.isLoggedin && (
                    <>
                        <BalanceInfoContainer>
                            <CoinContainer>
                                <Tooltip
                                    overlayClassName="topUpCoinTooltip"
                                    title="Coins Detail"
                                    color={'white'}
                                    overlayInnerStyle={{
                                        borderRadius: '8px',
                                        color: 'var(--vb-ref-color-neutral-50, #333)',
                                        fontFamily: 'Readex Pro',
                                        fontDize: '14px',
                                        fontDtyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: '160%',
                                        letterDpacing: '0.15px',
                                    }}
                                >
                                    <CoinBalanceContainer
                                        onClick={() => {
                                            router.push('/coin-detail')
                                        }}
                                    >
                                        <Coin />
                                        <Balance>
                                            {convertToIndonesianCurrency(
                                                totalCoins
                                            )}
                                        </Balance>
                                    </CoinBalanceContainer>
                                </Tooltip>

                                <Link href={'/coin'}>
                                    <a
                                        onClick={(e) => {
                                            e.preventDefault()

                                            EventTracking(
                                                'Top up coins page from web opened',
                                                {
                                                    userType:
                                                        session?.isLoggedin
                                                            ? 'registered'
                                                            : 'guest',
                                                    loginAuth: session?.type,
                                                    userName: session?.userName,
                                                    userId: session?.userInfo
                                                        ?.id,
                                                    activeCoinBalance:
                                                        totalCoins,
                                                    source: 'coin icon ',
                                                    contentId: null,
                                                    contentTitle: null,
                                                    genres: null,
                                                    catalogId: null,
                                                    catalogTitle: null,
                                                    catalogSource: null,
                                                },
                                                session
                                            )

                                            router.push('/coin')
                                        }}
                                        style={{ marginBottom: '3px' }}
                                    >
                                        <Add />
                                    </a>
                                </Link>
                            </CoinContainer>
                            <DiamondBalanceContainer>
                                <Diamond />
                                <Balance>
                                    {convertToIndonesianCurrency(
                                        diamondBalance
                                    )}
                                </Balance>
                            </DiamondBalanceContainer>
                        </BalanceInfoContainer>
                        <UserDetailContainer>
                            <Dropdown
                                overlayClassName="dropdownOverlay"
                                menu={{
                                    items,
                                }}
                                trigger={['click']}
                                onOpenChange={(e) => setIsOpen(e)}
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space size={16}>
                                        <Avatar
                                            loading="lazy"
                                            size={32}
                                            src={compressedUserPhoto(
                                                session?.userInfo
                                            )}
                                        />
                                        {/* <UserName>
                                        {session?.userInfo?.displayName}
                                    </UserName> */}
                                        {isOpen ? (
                                            <img
                                                alt="arrow up"
                                                src="/img/arrow-up.svg"
                                                loading="lazy"
                                            />
                                        ) : (
                                            <img
                                                alt="arrow down"
                                                src="/img/arrow-down.svg"
                                                loading="lazy"
                                            />
                                        )}
                                    </Space>
                                </a>
                            </Dropdown>
                        </UserDetailContainer>
                    </>
                )}
            </InfoWrapper>
        </Container>
    )
}
